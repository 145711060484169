import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { TOKEN_COOKIE, EXPIRE_COOKIE, REFRESH_COOKIE, EXPIRE_TIME } from '../services/config';
import { signOut } from '../actions/user';

class Header extends Component {
	onLogout = async e => {
		e.preventDefault();
		localStorage.removeItem(TOKEN_COOKIE);
		localStorage.removeItem(EXPIRE_COOKIE);
		localStorage.removeItem(REFRESH_COOKIE);
		localStorage.removeItem(EXPIRE_TIME);
		this.props.signOut();
		this.props.history.push('/login?user=logged-out');
	};

	render() {
		return (
			<div id="header">
				<div className="color-line" />
				<div id="logo" className="light-version">
					<span>CTX Admin</span>
				</div>
				<nav role="navigation">
					<div className="navbar-right">
						<ul className="nav navbar-nav no-borders">
							<li className="dropdown">
								<Link to="/logout" onClick={this.onLogout}>
									<i className="pe-7s-upload pe-rotate-90"></i>
								</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		);
	}
}

export default withRouter(connect(null, { signOut })(Header));
