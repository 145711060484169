import moment from 'moment';
import numeral from 'numeral';

import { TOKEN_COOKIE, EXPIRE_COOKIE, API_URI, REFRESH_COOKIE, EXPIRE_TIME } from './config';

const checkStatus = async response => {
	if (!response.ok) {
		if (response.statusText === 'Forbidden') {
			localStorage.removeItem(TOKEN_COOKIE);
			localStorage.removeItem(EXPIRE_COOKIE);
		}
		const message = await response.text();
		const err = JSON.parse(message);
		throw Object.freeze({ message: err.error });
	}
	return response;
};

const headers = token => {
	const jwt = `Bearer ${token}`;
	return { ...defaultHeaders, Authorization: jwt };
};

const parseJSON = response => response.json();

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

export const HttpRequest = async (url, method, authed = false, data) => {
	const token = localStorage.getItem(TOKEN_COOKIE);
	const response = await fetch(url, {
		method: method,
		headers: authed ? headers(token) : { ...defaultHeaders },
		body: JSON.stringify(data),
	});
	const result = await checkStatus(response);
	return parseJSON(result);
};

export const checkUser = () => {
	return localStorage.getItem(TOKEN_COOKIE);
};

export const parseDate = (date, hasTime = false) => {
	return !hasTime ? moment.unix(date).format('D-MMM-YYYY') : moment.unix(date).format('D-MMM-YYYY HH:mm:ss');
};

const refreshData = async (token, timeout, history) => {
	try {
		const data = { token };
		const rs = await HttpRequest(`${API_URI}/token/refresh`, 'POST', true, data);
		const user = rs.user;
		localStorage.setItem(TOKEN_COOKIE, user.id_token);
		localStorage.setItem(EXPIRE_COOKIE, user.expires_in);
		localStorage.setItem(REFRESH_COOKIE, user.refresh_token);

		const gap = 60 * 10;
		const tte = moment().unix() + (parseInt(user.expires_in, 10) - gap);
		localStorage.setItem(EXPIRE_TIME, tte);

		clearTimeout(timeout);
		startTimeout({ refreshToken: user.refresh_token });
	} catch(e) {
		clearTimeout(timeout);
		localStorage.removeItem(TOKEN_COOKIE);
		localStorage.removeItem(EXPIRE_COOKIE);
		localStorage.removeItem(REFRESH_COOKIE);
		localStorage.removeItem(EXPIRE_TIME);
		console.log(e);
		try {
			history.push('/login?user=session-expired');
		} catch(err){
			window.location.reload();
		}
	}
};

export const startTimeout = (user, history) => {
	const timeToExpiry = (localStorage.getItem(EXPIRE_TIME) - moment().unix()) * 1000;
	let start = setTimeout(() => refreshData(user.refreshToken, start, history), timeToExpiry);
};

export const formatCurrency = (amount, curr) => `${curr}${numeral(amount).format('0,0.00')}`;
