import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

import configureStore from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './services/history';
import { stopPreloading } from './actions/general';
import { checkUser, HttpRequest, startTimeout } from './services/utilities';
import { API_URI, resolveAPI, EXPIRE_COOKIE, TOKEN_COOKIE, REFRESH_COOKIE, EXPIRE_TIME } from './services/config';
import { SIGN_OUT } from './actions/types';
import Notify from './services/notify';
import { loginUser } from './actions/user';

Notify.notifications.subscribe(alert => alert instanceof Function && alert());
const store = configureStore();

const token = checkUser();
if (token) {
	const expiresIn = localStorage.getItem(EXPIRE_COOKIE);
	const refreshToken = localStorage.getItem(REFRESH_COOKIE);
	const data = { expiresIn, refreshToken, };
	HttpRequest(`${API_URI}${resolveAPI}`, 'POST', true, { ...data })
		.then(rs => {
			startTimeout(rs.user, history);
			localStorage.setItem(TOKEN_COOKIE, rs.user.idToken);
			localStorage.setItem(EXPIRE_COOKIE, rs.user.expiresIn);
			localStorage.setItem(REFRESH_COOKIE, rs.user.refreshToken);
			store.dispatch(loginUser(rs.user));
			store.dispatch(stopPreloading());
			if(history.location.pathname === '/login'){
				history.push('/');
			}
		})
		.catch(error => {
			localStorage.removeItem(EXPIRE_COOKIE);
			localStorage.removeItem(TOKEN_COOKIE);
			localStorage.removeItem(REFRESH_COOKIE);
			localStorage.removeItem(EXPIRE_TIME);
			store.dispatch({ type: SIGN_OUT });
			store.dispatch(stopPreloading());
			history.push('/login?not-authenticated');
		});
} else {
	store.dispatch(stopPreloading());
	history.push('/login?not-authenticated');
}

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root'),
);

serviceWorker.unregister();
