import * as types from '../actions/types';

const INITIAL_STATE = {
	preloading: true,
};

const general = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.START_PRELOADING:
			return { ...state, preloading: true };
		case types.STOP_PRELOADING:
			return { ...state, preloading: false };
		case types.SIGN_OUT:
			return { ...state, preloading: false };
		default:
			return state;
	}
};

export default general;
