export const EXPIRE_COOKIE = '_user_expire__CTX@';
export const TOKEN_COOKIE = '_token__CTX@';
export const REFRESH_COOKIE = '_rftk__CTX@';
export const EXPIRE_TIME = '_expire__CTX@';

export const DEBUG_MODE = process.env.REACT_APP_DEBUG;
export const BASE_API = process.env.REACT_APP_URL;
if (DEBUG_MODE === 'true') {
	console.log(`debug: ${DEBUG_MODE}`);
}

export const API_URI = `${BASE_API}/api`;
export const resolveAPI = '/resolve-user';
export const loginAPI = '/login';
export const userAPI = '/users';
export const settingsAPI = '/settings';
export const transactionAPI = '/transactions';
export const adminAPI = '/admin';

export const routeList = [
	'',
	'login',
	'settings',
	'users',
	'transactions',
	'user',
];
