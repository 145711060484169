import React from 'react';
import { Link } from 'react-router-dom';

const Aside = ({ pathname }) => {
	return (
		<aside id="menu">
			<div id="navigation">
				<ul className="nav" id="side-menu">
					<li className={pathname === '/' ? 'active' : ''}>
						<Link to="/">
							<span className="nav-label">Dashboard</span>
						</Link>
					</li>
					<li className={pathname === '/settings' ? 'active' : ''}>
						<Link to="/settings">
							<span className="nav-label">Settings</span>
						</Link>
					</li>
					<li className={pathname === '/users' ? 'active' : ''}>
						<Link to="/users">
							<span className="nav-label">Users</span>
						</Link>
					</li>
					<li className={pathname === '/transactions' ? 'active' : ''}>
						<Link to="/transactions">
							<span className="nav-label">Transactions</span>
						</Link>
					</li>
				</ul>
			</div>
		</aside>
	);
};

export default Aside;
