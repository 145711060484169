import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Splash from './components/Splash';
import Header from './components/Header';
import Aside from './components/Aside';
import { routeList } from './services/config';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const Settings = lazy(() => import('./pages/Settings'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Transactions = lazy(() => import('./pages/Transactions'));
const SingleUser = lazy(() => import('./pages/SingleUser'));

class App extends Component {
	render() {
		const { loggedIn, preloading, location } = this.props;
		const routes = location.pathname.split('/');
		const isFound = routes[1] === '' || routeList.find(route => route === routes[1]);
		return preloading ? (
			<Splash />
		) : (
			<>
				<ToastContainer autoClose={3500} />
				<Suspense fallback={<Splash />}>
					{loggedIn && (
						isFound ? (
							<>
								<Header />
								<Aside pathname={location.pathname} />
							</>
						) : <div className="color-line" />
					)}
					<div id={loggedIn && isFound ? 'wrapper' : ''}>
						<Switch>
							<Route exact path="/" component={Dashboard} />
							<Route path="/login" component={Login} />
							<Route path="/settings" component={Settings} />
							<Route path="/users" component={Users} />
							<Route path="/transactions" component={Transactions} />
							<Route path="/user/:id" component={SingleUser} />
							<Route path="/not-found" component={NoMatch} />
							<Route component={NoMatch} />
						</Switch>
						{/* {loggedIn && isFound && (
							<footer className="footer">
								<span className="pull-right">Change Transact</span>
								HelpPay Inc. 2019
							</footer>
						)} */}
					</div>
				</Suspense>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		preloading: state.general.preloading,
		loggedIn: state.user.loggedIn,
	};
};

export default withRouter(connect(mapStateToProps)(App));
