import React from 'react';

const Splash = () => {
	return (
		<div className="splash">
			<div className="color-line"></div>
			<div className="splash-title">
				<h1>Change Transact</h1>
				<p>Loading...</p>
				<div className="spinner">
					<div className="rect1" />
					<div className="rect2" />
					<div className="rect3" />
					<div className="rect4" />
					<div className="rect5" />
				</div>
			</div>
		</div>
	);
};

export default Splash;
