import * as types from './types';

export const startPreloading = () => {
	return {
		type: types.START_PRELOADING,
	};
};

export const stopPreloading = () => {
	return {
		type: types.STOP_PRELOADING,
	};
};
